.services-section {
  padding: 8.8rem 0;
}
.services-section h1 {
  margin-bottom: 5.8rem;
  color: var(--secondary-color);
  text-align: center;
}
.services-section .container h2 {
  text-align: center;
  margin-bottom: 5.8rem;
  color: var(--secondary-color);
}
.services-section .grid-services-section {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2.8rem;
  column-gap: 2.8rem;
}
.services-section .grid-services-section .box-service {
  display: grid;
  grid-template-columns: 1fr;
  background: var(--secondary-color);
}
.services-section .grid-services-section .box-service .box-service__content {
  background: var(--primary-color);
  padding: 1.8rem;
}
.services-section .grid-services-section .box-service .box-service__content a {
  color: var(--white-color);
  text-decoration: none;
}
.services-section .grid-services-section .box-service .box-service__image img {
  display: block;
  object-fit: cover;
  height: 290px;
}
.services-section .grid-services-section .box-service-page {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2.8rem;
  column-gap: 2.8rem;
}
.services-section .grid-services-section .box-service-page .box-service-page__content h2 {
  text-align: left;
}
.services-section .grid-services-section .box-service-page .box-service-page__image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.services-section .grid-services-section .box-service-page .box-service-page__image img {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .services-section .grid-services-section .box-service {
    grid-template-columns: repeat(2, 1fr);
  }

  .services-section .grid-services-section .box-service-page {
    grid-template-columns: 20% 1fr;
  }
}
@media (min-width: 992px) {
  .services-section .grid-services-section {
    grid-template-columns: repeat(2, 1fr);
  }
}